@import "../../theme/colors";

.developer-posts {

    .project-container {
        .project-child {

            min-width: 50%;

        }

        .developer-links {
            display: flex;
            min-width: 200px;
            overflow: hidden;
            height: 100%;

            .project-image {
                width: 200%;
                height: 200%;
                transition: 0.2s;
                object-fit: cover;
                z-index: 1;

                &:hover {
                    transform: scale(1.02);
                }
            }

            .btn-github {
                display: flex;
                margin: 0;
                width: 20%;
                justify-content: center;
                align-items: center;
                transition: .2s;

            }
        }
    }
}