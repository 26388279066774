@import "./developer";
@import "./music";
@import "../../theme/colors";
@import "../tiles/person";

.project-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 1em;
    border-bottom: 2px solid #80808082;
    transition: background-color 0.2s, color 0.2s;
    margin-bottom: 2em;
    overflow: hidden;



    .project-description {
        display: flex;
        align-items: baseline;
    }

    .project-title {
        margin-bottom: 1em;
    }

    .project-content {
        margin-bottom: 1em;
    }

    .project-child {
        display: flex;
    }


}

@media (max-width: 756px) {
    .project-container {
        flex-direction: column;
    }

}

.container {
    padding: 1em;
    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;

    .child {
        padding: 1em;
    }
}

.scroll-container {
    display: flex;
    width: 100%;
    margin-top: 20%;

}

.scroll-list {
    width: 100%;
    justify-content: space-between;

}


.person-container {
    position: absolute;
    top: 1em;
    right: 1em;
    width: 6em;
    height: 6em;
}




// .scroll-container {
//     justify-content: center;
//     align-items: center;
//     height: 90vh;

//     width: 100%;
//     overflow: hidden;
// }

// .scroll-list {
//     display: flex;
//     flex-direction: column;
//     overflow-y: scroll;
//     scroll-snap-type: y mandatory;
//     height: 200%;
//     padding: 0;
//     box-sizing: border-box;
//     width: 100%;
//     position: absolute;
//     top: 0;
// }

// .scroll-list-item {
//     /* Ensure each item takes the full viewport height */
//     scroll-snap-align: center;
//     background-color: #f0f0f0;
//     padding: 20px;
//     border-radius: 10px;
//     display: flex;
//     margin-bottom: 50%;
//     justify-content: center;
//     align-items: center;
//     box-sizing: border-box;
//     text-align: center;
// }