@import "../theme/colors";

.background {
    z-index: -2;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    max-width: 1500px;
    height: 100vh;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;

    .square {
        position: absolute;
    }

    .square-1 {
        animation: rotate 600s linear infinite;
        rotate: 45deg;
        height: 2000px;
        width: 2000px;
        top: -80em;
        left: -80em;
    }

    .square-2 {
        animation: rotate 550s reverse infinite;
        height: 1500px;
        width: 1500px;
        top: -50em;
        right: -40em;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }

    .square-3 {
        animation: rotate 450s linear infinite;
        height: 1200px;
        width: 1200px;
        bottom: -20em;
        left: -40em;
    }

    .square-4 {
        animation: rotate 530s linear infinite;
        height: 1500px;
        width: 1500px;
        rotate: 45deg;
        right: -80em;
    }

    .square-5 {
        animation: rotate 520s reverse infinite;
        height: 1500px;
        width: 100vw;
        bottom: -80em;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}