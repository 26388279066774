html {
    height: 100%;
}

body {
    max-height: 100vh;
    font-family: system-ui;
    font-size: 1.125rem;
    line-height: 1.5;

}

img,
svg,
video {
    max-width: 100%;
    display: block;
}