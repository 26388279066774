@import "./theme/colors";
@import "./sections/sections";
@import "./components/components";

.row,
.column {
    display: flex;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}


.split {
    min-width: 50%;
}