.person-container {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    position: relative;
}


#person {
    position: absolute;
    top: 5%;
    height: 150%;
    width: 150%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .head,
    .neck {
        background-color: bisque;
    }

    .head {
        z-index: 2;
        height: 30%;
        width: 30%;
        position: relative;
        border-bottom: solid black;
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        margin-bottom: -5%;
        transition: transform .5s ease;

        .hair {
            height: 40%;
            position: relative;
            margin-top: -10%;
            z-index: 3;

            .hair-left,
            .hair-right,
            .hair-center {
                position: absolute;
                background-color: rgb(134, 134, 41);
                height: 80%;
                width: 100%;
                box-shadow: 2px 2px rgba(0, 0, 0, 0.297);

            }

            .hair-center {
                height: 40%;
                width: 50%;
                right: 30%;

            }

            .hair-left {
                width: 20%;
                height: 100%;
                left: 0;
                transform: rotate(30deg);
                border-bottom-left-radius: 20%;
                border-bottom-right-radius: 20%;
            }

            .hair-right {
                width: 70%;
                right: -20%;
                height: 90%;
                top: 0;
                border-bottom-right-radius: 50%;
                border-top-right-radius: 20%;
                border-top-left-radius: 50%;
                transform: rotate(60deg);
            }
        }

        .eyes-position {
            position: relative;
            height: 30%;
            // background-color: red;
            z-index: 1;
            transition: transform 0.2s ease-out;

            .eye {
                background-color: black;
                width: 15%;
                height: 80%;
                position: absolute;
                border-radius: 50%;
                // animation: blink 1s step-end 1;
                // animation-delay: 5s;
            }

            .left-eye {
                left: 20%;
            }

            .right-eye {
                right: 20%;
            }

            @keyframes blink {

                0%,
                100% {
                    height: 80%;
                }

                50% {
                    height: 0;
                }
            }
        }

        // .glasses {
        //     position: absolute;
        //     height: 100%;
        //     width: 100%;
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: space-between;

        //     .line {
        //     }

        //     .line-left,
        //     .line-right {
        //         height: 5%;
        //         width: 10%;
        //         background-color: black;
        //     }
        //     .circle {
        //         height: 100%;
        //         height: 100%;
        //         border: 2px solid black;
        //     }
        //     .line-left {
        //         left: 0;

        //     }
        //     .line-right {
        //         right: 0;
        //     }

        // }
    }

    .neck-container {
        height: 10%;
        width: 15%;
        position: relative;
        z-index: 1;
        transition: transform 1s ease-out;

        .neck {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: bisque;
        }

        .circle {
            position: absolute;
            background-color: bisque;
            height: 100%;
            width: 100%;
            bottom: -50%;
            border-radius: 50%;

        }
    }

    .body {
        // background-color: blue;
        border-top-right-radius: 20%;
        border-top-left-radius: 20%;
        width: 50%;
        height: 40%;
    }
}