@import "./hamburger";
@import "./darkmode";
@import "./scrollBtn";
@import "src/scss/theme/_colors.scss";



a {
    cursor: pointer;
    text-decoration: none;
}

nav {
    a {
        display: flex;
        list-style: none;
        justify-content: center;
        text-decoration: none;
        transition: transform .5s;

        &:hover {}

        &.active {
            border-bottom: 2px solid $grey;
        }
    }

}

.btn {
    display: flex;
    padding: 1em;
    transition: .2s;
}

.btn-primary {
    width: fit-content;
    border-radius: 50px;
}

.icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: .2s;
}

.btn-linkedin,
.btn-github {
    margin: 1em;

    svg {
        transition: .2s;
    }

    &:hover {
        svg {
            transform: translateY(-10%);
        }
    }
}

.btn-hamburger,
.btn-darkmode {
    z-index: 11;
    overflow: hidden;
    border: none;
    padding: .5em;
    height: 50px;
    width: 50px;
    margin: 1em;
    transition: .2s;


    &:hover {
        .icon-container {
            opacity: .6;
        }
    }
}

.btn-darkmode {
    position: fixed;
}