nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    height: fit-content;
    z-index: 10;

    .btn-hamburger {
        position: absolute;
        top: 0;
        right: 0;
    }





    .dropdown-menu {
        display: flex;

        li {
            transition: .2s;
            list-style: none;

            a {
                display: flex;
                width: 100%;
                height: 100%;
                transition: .2s;
            }
        }
    }

    @media (min-width: 756px) {
        .dropdown.open {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100vw;
            height: 4em;

            .dropdown-menu {
                right: 0;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                width: 50%;

                li {
                    width: 30%;
                    padding: .5em;
                    margin: .2em;

                    a {
                        padding: .5em;
                    }
                }
            }
        }



    }

    @media (max-width: 756px) {
        .dropdown.open {
            width: 100vw;
            height: 100vh;
        }

        .dropdown-menu {
            justify-content: space-around;
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin: 0;
            margin-top: 5em;
            padding: 0;


            li {
                padding: 1em;
                width: 50%;
                margin-bottom: 10%;

                a {
                    padding: 1em;
                }
            }
        }
    }

}