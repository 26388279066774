.music-posts {
    // iframe {
    //     width: min-content;
    //     height: min-content;
    //     min-width: 50%;
    // }

    .project-container {


        .project-description {
            display: flex;
            width: 100%;

            .project-content {
                display: flex;
                justify-content: center;



                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    @media (max-width: 756px) {
        .project-container {}

    }

}