.icon-darkmode {

    .light-ray,
    .sun {
        // background-color: black;
        position: absolute;
    }


    .light-ray {
        height: 10%;
        width: 25%;
        border-radius: 50px;

    }

    .top-left {
        transform: rotate(45deg);
    }

    .top-middle {
        top: 10%;
    }

    .top-right {
        transform: rotate(-45deg);
    }

    .bottom-left {
        transform: rotate(-45deg);
    }

    .bottom-middle {
        bottom: 10%;
    }

    .bottom-right {
        transform: rotate(45deg);
    }

    .center-left {
        left: 0;
    }

    .center-right {
        right: 0;
    }

    .top-left,
    .top-right {
        top: 22%;
    }

    .bottom-left,
    .bottom-right {
        bottom: 22%;
    }

    .top-middle,
    .bottom-middle {
        transform: rotate(90deg);
    }

    .top-middle,
    .bottom-middle,
    .center-left,
    .center-right {
        width: 25%;
    }

    .top-left,
    .bottom-left {
        left: 10%;
    }

    .top-right,
    .bottom-right {
        right: 10%;
    }

    .sun,
    .moon {
        height: 40%;
        width: 40%;
        border-radius: 50%;
    }

    .moon {
        position: absolute;

        left: 100%;
        height: 60%;
        width: 60%;
        opacity: 0;
    }



    &.dark {

        .light-ray,
        .sun {
            // background-color: white;
            position: absolute;
        }

        .top-middle,
        .bottom-middle,
        .center-left,
        .center-right,
        .top-right,
        .bottom-right,
        .top-left,
        .bottom-left {
            animation: hideRays .3s ease forwards;
        }

        .sun {

            animation: changeSize .3s ease forwards;
        }

        .moon {
            animation: moveToLeft .5s ease forwards;
        }
    }

    &.light {

        .light-ray,
        .sun {
            // background-color: black;
            position: absolute;
        }

        .top-middle,
        .bottom-middle,
        .center-left,
        .center-right,
        .top-right,
        .bottom-right,
        .top-left,
        .bottom-left {
            animation: hideRaysReverse .3s ease forwards;
        }

        .sun {
            animation: changeSizeReverse .3s ease forwards;
        }

        .moon {
            background-color: transparent;
            animation: moveToLeftReverse .5s ease forwards;
        }
    }

    @keyframes moveToLeft {
        0% {
            left: 100%;

        }

        30% {
            opacity: 1;
        }

        100% {
            opacity: 1;
            left: 45%;
        }
    }

    @keyframes moveToLeftReverse {
        0% {
            right: 100%;
            opacity: 0;
        }

        100% {
            left: 100%;
            opacity: 1;

        }
    }

    @keyframes changeSize {
        0% {
            height: 40%;
            width: 40%;
        }

        100% {
            height: 60%;
            width: 60%;
        }
    }

    @keyframes changeSizeReverse {
        0% {
            height: 60%;
            width: 60%;
        }

        100% {
            height: 40%;
            width: 40%;
        }
    }

    @keyframes hideRays {
        0% {}

        100% {
            width: 0;
            opacity: 0;
        }

    }

    @keyframes hideRaysReverse {
        0% {
            width: 0;
            opacity: 0;
        }

        100% {
            width: 25%;
            opacity: 1;
        }

    }
}